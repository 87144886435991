import { toast, ToastContainerProps } from 'react-toastify';

const containerProps: ToastContainerProps = {
  theme: 'light',
  position: toast.POSITION.TOP_RIGHT,
  newestOnTop: true,
  closeButton: true,
  autoClose: 4000,
};

export { containerProps };
