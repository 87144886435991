import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthUtils } from 'src/services/Auth/Auth.utils';
import { appRoutes, RouteObject } from 'src/routes/routes.types';

type ProtectedRouteProps = {
  route: RouteObject;
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ route }) => {
  const location = useLocation();
  const isLogged = !AuthUtils.isExpired();

  if (!isLogged && route.restricted?.includes('guest')) {
    return <Navigate to={appRoutes.login} state={{ from: location }} replace />;
  }

  if (isLogged) {
    const userType = AuthUtils.getPayload()?.user_type;

    if (route.restricted?.includes(userType as any)) {
      return <Navigate to={appRoutes.profile} replace />;
    }
  }

  return (
    <route.layout>
      <Outlet />
    </route.layout>
  );
};
