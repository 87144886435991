import React, { useEffect } from 'react';
import { cn } from 'src/lib/utils';
import { Breadcrumbs } from '@becreatives/becreatives-ui';
import { Link } from 'src/components/ui/link';
import { appRoutes } from 'src/routes';
import { generatePath, useNavigate } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import { useTranslation } from 'react-i18next';
import { useRequestId } from 'src/features/requests/use-request-id';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { DecoratedRequestProvider } from 'src/features/requests/use-decorated-request-context';
import { ErrorBoundary } from 'react-error-boundary';
import { RequestQueryProvider } from 'src/features/requests/use-request-query-context';
import { CompanyQueryProvider } from 'src/features/companies/use-company-query-context';
import { useCompanyId } from 'src/features/companies/use-company-id';
import { useCurrentCompanyRequestsCounts } from 'src/models';
import {
  RequestDefaultPageMainContent,
  RequestDefaultPageSkeleton,
  RequestPageContainer,
  RequestPageContentMain,
} from 'src/pages/request/request-page/request-default-page';
import {
  RequestMultiOutcomePageMainContent,
  RequestMultiOutcomePageSideContent,
  RequestMultiOutcomeSkeleton,
} from 'src/pages/request/request-page/request-multi-outcome-page';
import { RequestResponse } from 'src/lib/services/api/request-api';
import { RequestMultiOutcomePageContentTop } from 'src/pages/request/request-page/request-multi-outcome-page-content-top';
import { RequestOutcomePageContentTop } from 'src/pages/request/request-page/request-outcome-page';
import { RequestDefaultPageContentTop } from 'src/pages/request/request-page/request-default-page-content-top';
import { SpaceErrorFallback } from 'src/features/fallback';

const RequestPageFallbackContent: React.FC<{ error: any; resetErrorBoundary: () => any }> = ({
  error,
  resetErrorBoundary,
}) => {
  const { t } = useTranslation();
  const companyId = useCompanyId();
  const requestId = useRequestId();
  const navigate = useNavigate();

  return (
    <>
      <div className={'tw-flex tw-w-full tw-flex-wrap tw-items-center tw-gap-12'}>
        <Breadcrumbs
          crumbs={[]}
          back={() => navigate(generatePath(appRoutes.companyRequestsList, { companyId }))}
        />

        <Button
          asChild
          size={'sm'}
          variant={'default'}
          className={'tw-mx-auto tw-grow sm:tw-me-0 sm:tw-ms-auto sm:tw-grow-0'}
        >
          <Link
            to={generatePath(appRoutes.requestRevisions, {
              companyId,
              requestId,
            })}
            variant={'unset'}
          >
            {t('models/revision:name.plural')}
          </Link>
        </Button>
      </div>

      <SpaceErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />
    </>
  );
};

const RequestPageSkeletonResolver: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    flow?: RequestResponse['flow'];
  }
> = ({ flow = 'default', className, ...props }) => {
  switch (flow) {
    case 'default':
      return <RequestDefaultPageSkeleton className={cn('tw-gap-4 tw-col-span-12', className)} {...props} />;
    case 'multi_outcome':
      return <RequestMultiOutcomeSkeleton {...props} className={cn('tw-gap-4 tw-col-span-12', className)} />;
    case 'outcome':
      return <RequestDefaultPageSkeleton className={cn('tw-gap-4 tw-col-span-12', className)} {...props} />;
    default:
      return <RequestDefaultPageSkeleton className={cn('tw-gap-4 tw-col-span-12', className)} {...props} />;
  }
};

const RequestFlowMainContentResolver: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    flow?: RequestResponse['flow'];
  }
> = ({ flow = 'default', className, ...props }) => {
  switch (flow) {
    case 'multi_outcome':
      return (
        <>
          <>
            <RequestMultiOutcomePageMainContent className={'tw-col-span-12 laptop:tw-col-span-8'} />
            <RequestMultiOutcomePageSideContent
              className={
                'tw-hidden tw-items-center tw-rounded-lg laptop:tw-col-span-4 laptop:tw-flex laptop:tw-pt-15'
              }
            />
          </>
        </>
      );
    default:
      return (
        <RequestDefaultPageMainContent className={cn('tw-col-span-12', className)} {...props} />
      );
  }
};
RequestFlowMainContentResolver.displayName = 'RequestFlowContentResolver';

const RequestFlowContentTopResolver: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    flow?: RequestResponse['flow'];
  }
> = ({ flow = 'default', ...props }) => {
  switch (flow) {
    case 'default':
      return <RequestDefaultPageContentTop {...props} />;
    case 'multi_outcome':
      return <RequestMultiOutcomePageContentTop {...props} />;
    case 'outcome':
      return <RequestOutcomePageContentTop {...props} />;
    default:
      return <div {...props}>Unknown request flow type content top</div>;
  }
};

const RequestPage: React.FC = () => {
  const companyId = useCompanyId();
  const requestId = useRequestId();

  const { review, query: companyRequestsCountsQuery } = useCurrentCompanyRequestsCounts();

  useEffect(() => {
    if (companyRequestsCountsQuery.isSuccess) {
      review(requestId!);
    }
  }, [companyRequestsCountsQuery.isSuccess]);

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }: any) => (
            <RequestPageFallbackContent error={error} resetErrorBoundary={resetErrorBoundary} />
          )}
          onReset={reset}
        >
          <React.Suspense fallback={<RequestPageSkeletonResolver flow={'default'} />}>
            <CompanyQueryProvider id={companyId}>
              <RequestQueryProvider id={requestId}>
                {({ data }) => (
                  <DecoratedRequestProvider request={data}>
                    <RequestPageContainer>
                      <RequestFlowContentTopResolver flow={data?.flow} />

                      <RequestPageContentMain className={'tw-grid-cols-1 md:tw-grid-cols-12'}>
                        <RequestFlowMainContentResolver flow={data?.flow} />
                      </RequestPageContentMain>
                    </RequestPageContainer>
                  </DecoratedRequestProvider>
                )}
              </RequestQueryProvider>
            </CompanyQueryProvider>
          </React.Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

export { RequestPageContainer, RequestPage };
