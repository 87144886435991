import React from 'react';
import { ErrorMessage as HookErrorMessage } from '@hookform/error-message';
import { If } from 'src/components/If';

type Props = {
  name: string;
};

const ErrorMessage: React.FC<Props> = ({ name }) => {
  const className =
    'tw-mt-1 tw-text-sm tw-font-medium read-newlines tw-text-destructive validation-error';

  return (
    <HookErrorMessage
      name={name}
      render={({ message, messages }) => (
        <If when={!!messages} else={<p className={className}>{message}</p>}>
          {messages &&
            Object.entries(messages).map(([type, message]) => (
              <p key={type} className={className}>
                {message}
              </p>
            ))}
        </If>
      )}
    />
  );
};

export type { Props as ErrorMessageProps };
export { ErrorMessage };
