import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/ui/button';
import { useRegisterSW } from 'virtual:pwa-register/react';

/**
 * This function will register a periodic sync check every hour.
 */
const period = 60 * 60 * 1000;

function registerPeriodicSync(period: number, swUrl: string, r: ServiceWorkerRegistration) {
  if (period <= 0) return;

  setInterval(async () => {
    if ('onLine' in navigator && !navigator.onLine) return;

    const resp = await fetch(swUrl, {
      cache: 'no-store',
      headers: {
        cache: 'no-store',
        'cache-control': 'no-cache',
      },
    });

    if (resp?.status === 200) await r.update();
  }, period);
}

const AppUpdateDialog = () => {
  const { t } = useTranslation();

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      if (period <= 0) return;
      if (r?.active?.state === 'activated') {
        registerPeriodicSync(period, swUrl, r);
      } else if (r?.installing) {
        r.installing.addEventListener('statechange', (e) => {
          const sw = e.target as ServiceWorker;
          if (sw.state === 'activated') registerPeriodicSync(period, swUrl, r);
        });
      }
    },
  });

  const close = () => {
    setNeedRefresh(false);
  };

  return needRefresh ? (
    <div
      className={
        'tw-fixed tw-inset-x-0 tw-bottom-0 tw-z-50 tw-border tw-bg-background tw-p-6 tw-text-base tw-shadow-lg sm:tw-bottom-4 sm:tw-left-4 sm:tw-max-w-xs sm:tw-rounded-lg '
      }
    >
      <strong className={'tw-mb-2 tw-block'}>{t('A new update is available! 🚀✨')}</strong>

      <div>{t('Reload the app to see the latest changes.')}</div>

      <div className={'tw-mt-4 tw-flex tw-gap-4'}>
        <Button className={'tw-w-full'} size={'sm'} variant={'destructiveOutline'} onClick={close}>
          {t('Cancel')}
        </Button>
        <Button
          className={'tw-w-full'}
          size={'sm'}
          variant={'brand'}
          onClick={() => updateServiceWorker(true)}
        >
          {t('Reload')}
        </Button>
      </div>
    </div>
  ) : null;
};

export default AppUpdateDialog;
