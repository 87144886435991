import { ValueOfObject } from 'src/types/generics';
import { MediaObject } from '../MediaClient';

const revisionType = {
  firstContact: 'first_contact',
  revision: 'revision',
  finalFiles: 'final_files',
} as const;

type RevisionType = ValueOfObject<typeof revisionType>;

interface RevisionAirtableObject {
  revision_url: string;
}

interface RevisionResponse {
  id: string;
  type: RevisionType | string;
  media?: MediaObject[];
  airtable?: RevisionAirtableObject;
  index: number;
  rating?: number;
  created_at: string;
}

interface StoreRevisionRequest {
  type: RevisionType;
  media: {
    default: MediaObject[];
    transcript?: MediaObject[];
    project_files?: MediaObject[];
  };
}

interface UpdateRevisionRequest {
  media: {
    default: MediaObject[];
    transcript?: MediaObject[];
    project_files?: MediaObject[];
  };
  is_final_files: boolean;
}

export type { RevisionType, RevisionResponse, StoreRevisionRequest, UpdateRevisionRequest };
export { revisionType };
