import { ChangeStatusRequest, requestStatus, RequestStatus } from 'src/api/services/RequestClient';
import { revisionType, RevisionType } from 'src/api/services/RevisionClient';
import { ChangeStatusFormObject } from './RequestStatusChangeForm.types';
import { extractFormErrors } from 'src/components/Form/Form.utils';
import { useProfile } from 'src/models';

const requestToRevisionStatusMap: Partial<Record<RequestStatus, RevisionType>> = {
  [requestStatus.delivered]: revisionType.revision,
};

const availableStatusMap: Record<RequestStatus, RequestStatus[]> = {
  [requestStatus.queued]: [requestStatus.currentlyEditing],
  [requestStatus.currentlyEditing]: [requestStatus.delivered],
  [requestStatus.delivered]: [requestStatus.currentlyEditing],
  [requestStatus.complete]: [],
};

const useRequestStatusMap = () => {
  const profile = useProfile();

  const getAvailableStatuses = (current?: RequestStatus): RequestStatus[] => {
    if (profile.isAdmin) {
      return Object.values(requestStatus);
    }

    if (profile.isEditor) {
      if (current === requestStatus.queued) {
        return [requestStatus.currentlyEditing];
      }

      if (current === requestStatus.complete) {
        return [];
      }
    }

    /**
     * Disabling this check because task requires to loosen up the restrictions
     * @link https://bitcat.atlassian.net/jira/software/c/projects/BEC/boards/25?modal=detail&selectedIssue=BEC-523
     */
    // return availableStatusMap[current];
    return Object.values(requestStatus);
  };

  const canChangeStatus = (current?: RequestStatus | string): boolean => {
    if (!current) {
      return false;
    }

    if (profile.isAdmin) {
      return true;
    }

    if (profile.isEditor) {
      if (current === requestStatus.complete) {
        return false;
      }
    }

    /**
     * Disabling this check because task requires to loosen up the restrictions
     * @link https://bitcat.atlassian.net/jira/software/c/projects/BEC/boards/25?modal=detail&selectedIssue=BEC-523
     */
    // return !!availableStatusMap[current].length;
    return true;
  };

  return {
    canChangeStatus,
    getAvailableStatuses,
  };
};

const makeRequestDataFromValues = ({
  status,
  editor_note,
  is_final_files,
  ...values
}: ChangeStatusFormObject): ChangeStatusRequest => {
  const data: ChangeStatusRequest = {
    status,
    editor_note,
  };

  if (status !== requestStatus.delivered) {
    return data;
  }

  data['revision'] = {
    type: is_final_files ? revisionType.finalFiles : revisionType.revision,
    media: {
      default: values.media.default,
      project_files: values.media.project_files,
      transcript: is_final_files ? values.media.transcript : undefined,
    },
  };
  data['is_final_files'] = is_final_files;

  return data;
};

const makeErrorsFromResponse = (e: any) => {
  const errors = extractFormErrors(e) ?? {};

  if ('revision' in errors && 'media' in errors.revision) {
    errors['media'] = errors.revision.media;
    delete errors.revision;
  }

  return errors;
};

// Formik breaks when trying to access 'media.collection.0.property', cba debugging it
export const flattenMediaCollectionsErrors = (errors: any) => {
  const collections = ['default', 'transcript', 'project_files'];

  collections.forEach((collection) => {
    const collectionErrors = errors.media?.[collection] ?? [];
    if (!collectionErrors.length) {
      return;
    }

    // Check if error is flat
    if (typeof collectionErrors[0] === 'string') {
      errors.media[collection] = collectionErrors;
      return;
    }

    // If it's not flat, build errors from properties
    const flat: string[] = [];
    collectionErrors.forEach((error: Record<string, string[]>) => {
      flat.push(...Object.values(error).flat());
    });
    errors.media[collection] = flat;
  });

  return errors;
};

export const mergeUploads = (uploads: Pick<ChangeStatusFormObject, 'media'>) => {
  const all = [];
  if (uploads.media.default.length) {
    all.push(...uploads.media.default);
  }
  if (uploads.media.transcript?.length) {
    all.push(...uploads.media.transcript);
  }
  if (uploads.media.project_files?.length) {
    all.push(...uploads.media.project_files);
  }

  return all;
};

export {
  requestToRevisionStatusMap,
  availableStatusMap,
  makeRequestDataFromValues,
  makeErrorsFromResponse,
  useRequestStatusMap,
};
