import { requestClient, requestStatus } from 'src/lib/services/api/request-api';
import { useQueryClient } from '@tanstack/react-query';
import { authQueries } from 'src/api/queries';
import { reactQueryClient } from 'src/providers/ReactQueryClientProvider';

const v2Key = (id: string) => [authQueries.request, 'v2', id];
const legacyKey = (id: string) => [authQueries.request, id];

type Data = Awaited<ReturnType<typeof requestClient.get>>;
type Updater = Parameters<typeof reactQueryClient.setQueryData<Data>>[1];

type RequestQueryMutationDecorator = {
  setData: (updater: Updater) => void;
  changeStatus: (
    data: Parameters<typeof requestClient.changeStatus>[1],
  ) => ReturnType<typeof requestClient.changeStatus>;
  resolveAttention: () => Promise<void>;
  createOutcome: (
    data: Parameters<typeof requestClient.storeOutcome>[1],
  ) => ReturnType<typeof requestClient.storeOutcome>;
  complete: () => Promise<void>;
  createFirstContact: (
    data: Parameters<typeof requestClient.storeRevision>[1],
  ) => ReturnType<typeof requestClient.storeRevision>;
  moveToEditing: () => ReturnType<typeof requestClient.changeStatus>;
  invalidate: (
    config?: Parameters<typeof reactQueryClient.invalidateQueries>[1],
  ) => ReturnType<typeof reactQueryClient.invalidateQueries>;
};

const useRequestQueryMutationDecorator = (id: string): RequestQueryMutationDecorator => {
  const client = useQueryClient();

  /**
   * Not implemented
   * @param data
   */
  const changeStatus: RequestQueryMutationDecorator['changeStatus'] = async (data) => {
    const updater: Updater = (prev) => {
      if (!prev) {
        return prev;
      }

      prev.status = data.status;
      prev.editor_note = data.editor_note ?? '';

      if ([requestStatus.currentlyEditing, requestStatus.delivered].includes(data.status as any)) {
        prev.revisions_count = data.revision
          ? (prev?.revisions_count ?? 0) + 1
          : prev.revisions_count;
      }

      return prev;
    };

    return requestClient.changeStatus(id, data).then((res) => {
      client.setQueryData<Data>(v2Key(id), updater);
      client.setQueryData<Data>(legacyKey(id), updater);

      return res;
    });
  };

  const moveToEditing: RequestQueryMutationDecorator['moveToEditing'] = async () =>
    changeStatus({ status: requestStatus.currentlyEditing });

  const resolveAttention = async (): Promise<void> => {
    const updater: Updater = (prev) => {
      if (!prev) {
        return prev;
      }

      prev.requested_attention_at = undefined;
      prev.attention_text = undefined;

      return prev;
    };

    return requestClient.resolveAttention(id).then(() => {
      client.setQueryData<Data>(v2Key(id), updater);
      client.setQueryData<Data>(legacyKey(id), updater);
    });
  };

  const complete = () => {
    const updater: Updater = (prev) => {
      if (!prev) {
        return prev;
      }

      prev.status = requestStatus.complete;
      prev.status_changed_at = new Date().toISOString();

      return prev;
    };

    return requestClient.complete(id).then(() => {
      client.setQueryData<Data>(v2Key(id), updater);
      client.setQueryData<Data>(legacyKey(id), updater);
    });
  };

  const createOutcome: RequestQueryMutationDecorator['createOutcome'] = async (data) => {
    const updater: Updater = (prev) => {
      if (!prev) {
        return prev;
      }

      prev.outcomes_count = (prev?.outcomes_count ?? 0) + 1;

      return prev;
    };

    return requestClient.storeOutcome(id, data).then((res) => {
      client.setQueryData<Data>(v2Key(id), updater);
      client.setQueryData<Data>(legacyKey(id), updater);

      return res;
    });
  };

  const createFirstContact: RequestQueryMutationDecorator['createFirstContact'] = async (data) => {
    const updater: Updater = (prev) => {
      if (!prev) {
        return prev;
      }

      prev.revisions_count = (prev?.revisions_count ?? 0) + 1;
      prev.status = requestStatus.currentlyEditing;

      return prev;
    };

    return requestClient.storeRevision(id, data).then((res) => {
      client.setQueryData<Data>(v2Key(id), updater);
      client.setQueryData<Data>(legacyKey(id), updater);

      return res;
    });
  };

  const invalidate = async (filters?: Parameters<typeof reactQueryClient.invalidateQueries>[1]) => {
    await reactQueryClient.invalidateQueries(v2Key(id), filters);
    await reactQueryClient.invalidateQueries(legacyKey(id), filters);
  };

  return {
    setData: (updater) => {
      client.setQueryData<Data>(v2Key(id), updater);
      client.setQueryData<Data>(legacyKey(id), updater);
    },
    changeStatus,
    resolveAttention,
    createOutcome,
    complete,
    createFirstContact,
    moveToEditing,
    invalidate,
  };
};

export { useRequestQueryMutationDecorator };
