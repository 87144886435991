import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { notify } from 'src/components/Toast';
import { AuthUtils } from 'src/services/Auth/Auth.utils';
import { GlobalHttpAbortController } from 'src/api/Http/GlobalHttpAbortController';
import { AxiosHttpError, isCancelError } from 'src/api/Http/Http.types';
import { HttpError } from 'src/api/Http/HttpError';

import { API_URL } from 'src/env.json';

class Http {
  axios: AxiosInstance;

  post: AxiosInstance['post'];

  get: AxiosInstance['get'];

  put: AxiosInstance['put'];

  delete: AxiosInstance['delete'];

  baseUrl = API_URL;

  constructor() {
    const defaultHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=utf-8',
      'Access-Control-Expose-Headers': 'Access-Control-*',
      'Access-Control-Allow-Headers':
        'Access-Control-*, Origin, X-Requested-With, Content-Type, Accept',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
      'Access-Control-Allow-Origin': '*',
      Allow: 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
      crossdomain: true,
    };

    this.axios = axios.create({
      withCredentials: true,
      headers: defaultHeaders,
      signal: GlobalHttpAbortController.signal,
    });

    if (!AuthUtils.isExpired()) {
      this.axios.defaults.headers.common['Authorization'] = `Bearer ${AuthUtils.getJwt().token}`;
    }

    this.post = this.axios.post;
    this.get = this.axios.get;
    this.put = this.axios.put;
    this.delete = this.axios.delete;

    this.axios.interceptors.request.use((request) => {
      return request;
    }, this.errorInterceptor);
    this.axios.interceptors.response.use((response: AxiosResponse) => {
      return response.data;
    }, this.errorInterceptor);
  }

  setBearer(accessToken: string): void {
    this.axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  errorInterceptor = (axiosError: AxiosHttpError): Promise<HttpError> => {
    const httpError = new HttpError(axiosError);

    if (!isCancelError(httpError)) {
      this.setToastError(httpError);
    }

    return Promise.reject(httpError);
  };

  convertObjectToQueryString = (params: any): string => {
    return Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
  };

  private setToastError = (error: HttpError): void => {
    if (error.code === 422) {
      return;
    }

    let message = '',
      title: string | undefined = undefined;

    if (error.message.includes('|')) {
      [title, message] = error.message.split('|');
    } else {
      message = error.message;
    }

    const toastId = window.btoa(`${error.code}_${message}`);
    notify(message, { title, type: 'error', toastId });
  };
}

export { Http };
