import React from 'react';
import { ToastContent } from 'react-toastify';
import { ToastContentProps } from 'react-toastify/dist/types';
import { textColorThemeMap } from './Message.utils';
import dompurify from 'dompurify';

interface Props<TData = unknown> extends ToastContentProps<TData> {
  title?: string;
  children?: ToastContent<any> | React.ReactNode | string;
}

const Message: React.FC<Props> = ({ title, children, ...props }): JSX.Element => {
  const messageProps: Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> &
    Pick<Props, 'children'> = {};
  if (typeof children === 'string') {
    messageProps['dangerouslySetInnerHTML'] = {
      __html: dompurify.sanitize(children),
    };
  }

  return (
    <>
      {title && <h5 className={`text-${textColorThemeMap[props.toastProps.theme]}`}>{title}</h5>}
      <div {...messageProps}>
        {children instanceof Function
          ? children(props)
          : typeof children === 'string'
          ? null
          : children}
      </div>
    </>
  );
};

export type { Props as MessageProps };
export { Message };
