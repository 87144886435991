import React from 'react';
import { useMakeQuery } from 'src/api/queries/useMakeQuery';
import { authQueries } from 'src/api/queries';
import { render } from 'src/lib/utils';
import { requestClient } from 'src/lib/services/api/request-api';
import { UseQueryOptionsObject } from 'src/api/queries/queries.utils';

type ApiResponse = Awaited<ReturnType<typeof requestClient.get>>;
type RequestQueryReturnType = ReturnType<typeof useMakeQuery<ApiResponse>>;

const RequestQueryContext = React.createContext<RequestQueryReturnType | undefined>(undefined);

const RequestQueryProvider: React.FC<{
  id: string;
  children: React.ReactNode | ((query: RequestQueryReturnType) => React.ReactNode);
  config?: UseQueryOptionsObject<ApiResponse>;
}> = ({ id, config = {}, children }) => {
  const query = useMakeQuery([authQueries.request, 'v2', id], () => requestClient.get(id), {
    suspense: true,
    retry: false,
    staleTime: 60 * 1000,
    ...config,
  });

  return (
    <RequestQueryContext.Provider value={query}>
      {render(children, query)}
    </RequestQueryContext.Provider>
  );
};

const useRequestQueryContext = () => {
  const context = React.useContext(RequestQueryContext);

  if (!context) {
    throw new Error('useRequestQuery must be used within a RequestQuery');
  }

  return context;
};

export { RequestQueryProvider, useRequestQueryContext };
